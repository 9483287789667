import { useState, useEffect } from "react";

import "./App.css";
import {
	TextField,
	Grid,
	ButtonGroup,
	Button,
	Paper,
	ThemeProvider,
} from "@mui/material";
import {
	Add,
	Remove,
	Language,
	LocalFireDepartment,
} from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";
import { red, grey } from "@mui/material/colors";

const theme = createTheme({
	palette: {
		primary: red,
		secondary: grey,
	},
});

const pattern = [
	"BR",
	"BL",
	"TL",
	"BR",
	"TR",
	"TL",
	"BR",
	"TR",
	"BL",
	"BR",
	"TR",
	"BL",
	"TL",
	"TR",
	"BL",
	"TL",
];

function App() {
	const [serial, setSerial] = useState("");
	const [noScratch, setNoScratch] = useState("");

	useEffect(() => {
		setNoScratch("");
	}, [serial]);

	const changeSerial = (e) =>
		e.target.value.length < 8 && setSerial(parseInt(e.target.value));

	const increase = () => serial + 1 <= 9999999 && setSerial(serial + 1);

	const decrease = () => serial - 1 >= 1000000 && setSerial(serial - 1);

	const calculate = () =>
		serial === "" ? null : setNoScratch(pattern[serial % 16]);

	return (
		<ThemeProvider theme={theme}>
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				style={{
					position: "absolute",
					height: "100%",
					top: 0,
					left: 0,
				}}
			>
				<Grid item>
					<Grid
						container
						style={{ padding: 6 }}
						direction="column"
						justify="center"
						alignItems="center"
						rowSpacing={3}
					>
						<Grid item>
							<img
								style={{ maxWidth: 280, marginTop: 14 }}
								src="/ezwin-card.svg"
								alt="instructions"
							/>
						</Grid>
						<Grid item style={{ display: "flex" }}>
							<TextField
								style={{ minWidth: 250, width: "100%" }}
								label="Numéro de ticket"
								placeholder="x'xxx'xxx"
								variant="outlined"
								type="number"
								pattern="[0-9]*"
								value={Number.isNaN(serial) ? "" : serial}
								onChange={changeSerial}
							/>
							<ButtonGroup variant="contained" orientation="vertical">
								<Button
									size="small"
									style={{ borderTopLeftRadius: 0, maxHeight: 28 }}
									onClick={increase}
								>
									<Add fontSize="small" />
								</Button>
								<Button
									size="small"
									style={{ borderBottomLeftRadius: 0 }}
									onClick={decrease}
								>
									<Remove fontSize="small" />
								</Button>
							</ButtonGroup>
						</Grid>
						<Grid item>
							<Button variant="contained" size="large" onClick={calculate}>
								Vérifier
							</Button>
						</Grid>
						<Grid item>
							<Grid
								style={{
									visibility: noScratch === "" ? "hidden" : null,
									minWidth: 300,
								}}
								container
								spacing={3}
							>
								<Grid item xs={6}>
									<Paper elevation={0} className="result">
										<LocalFireDepartment
											style={{
												visibility: noScratch === "TL" ? "hidden" : null,
											}}
											fontSize="large"
										/>
									</Paper>
								</Grid>
								<Grid item xs={6}>
									<Paper elevation={0} className="result">
										<LocalFireDepartment
											style={{
												visibility: noScratch === "TR" ? "hidden" : null,
											}}
											fontSize="large"
										/>
									</Paper>
								</Grid>
								<Grid item xs={6}>
									<Paper elevation={0} className="result">
										<LocalFireDepartment
											style={{
												visibility: noScratch === "BL" ? "hidden" : null,
											}}
											fontSize="large"
										/>
									</Paper>
								</Grid>
								<Grid item xs={6}>
									<Paper elevation={0} className="result">
										<LocalFireDepartment
											style={{
												visibility: noScratch === "BR" ? "hidden" : null,
											}}
											fontSize="large"
										/>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
						<Grid item style={{ textAlign: "center" }}>
							<Button
								variant="text"
								size="large"
								href="https://oggier.dev"
								startIcon={<Language />}
							>
								OGGIER.DEV
							</Button>
							<div id="wcb" class="carbonbadge"></div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}

export default App;
